import { default as addzeyhGGmvELMeta } from "/usr/src/nuxt3-app/pages/attachment/add.vue?macro=true";
import { default as _91id_93oMy51eepxuMeta } from "/usr/src/nuxt3-app/pages/attachments/[id].vue?macro=true";
import { default as dashboardzSNYueQDdCMeta } from "/usr/src/nuxt3-app/pages/dashboard.vue?macro=true";
import { default as indexEAfN1CIUWoMeta } from "/usr/src/nuxt3-app/pages/index.vue?macro=true";
import { default as loginUVQbZYKsyxMeta } from "/usr/src/nuxt3-app/pages/login.vue?macro=true";
import { default as matchesATFdXCrSX8Meta } from "/usr/src/nuxt3-app/pages/matches.vue?macro=true";
import { default as notesrlkSR9s2HEMeta } from "/usr/src/nuxt3-app/pages/notes.vue?macro=true";
import { default as predictlzdytUN7MxMeta } from "/usr/src/nuxt3-app/pages/predict.vue?macro=true";
import { default as scoutFroJopb6YKMeta } from "/usr/src/nuxt3-app/pages/scout.vue?macro=true";
import { default as settingsHEhVIocTCuMeta } from "/usr/src/nuxt3-app/pages/settings.vue?macro=true";
import { default as _91id_931583b0flOMMeta } from "/usr/src/nuxt3-app/pages/teams/[id].vue?macro=true";
import { default as _91id_93jF0kJABXlWMeta } from "/usr/src/nuxt3-app/pages/teams/attachments/[id].vue?macro=true";
import { default as index5xpbIqJnRtMeta } from "/usr/src/nuxt3-app/pages/teams/attachments/index.vue?macro=true";
import { default as indexiZOyfyVr5MMeta } from "/usr/src/nuxt3-app/pages/teams/index.vue?macro=true";
import { default as usersK9lU4U3bY3Meta } from "/usr/src/nuxt3-app/pages/users.vue?macro=true";
export default [
  {
    name: addzeyhGGmvELMeta?.name ?? "attachment-add",
    path: addzeyhGGmvELMeta?.path ?? "/attachment/add",
    meta: addzeyhGGmvELMeta || {},
    alias: addzeyhGGmvELMeta?.alias || [],
    redirect: addzeyhGGmvELMeta?.redirect,
    component: () => import("/usr/src/nuxt3-app/pages/attachment/add.vue").then(m => m.default || m)
  },
  {
    name: _91id_93oMy51eepxuMeta?.name ?? "attachments-id",
    path: _91id_93oMy51eepxuMeta?.path ?? "/attachments/:id()",
    meta: _91id_93oMy51eepxuMeta || {},
    alias: _91id_93oMy51eepxuMeta?.alias || [],
    redirect: _91id_93oMy51eepxuMeta?.redirect,
    component: () => import("/usr/src/nuxt3-app/pages/attachments/[id].vue").then(m => m.default || m)
  },
  {
    name: dashboardzSNYueQDdCMeta?.name ?? "dashboard",
    path: dashboardzSNYueQDdCMeta?.path ?? "/dashboard",
    meta: dashboardzSNYueQDdCMeta || {},
    alias: dashboardzSNYueQDdCMeta?.alias || [],
    redirect: dashboardzSNYueQDdCMeta?.redirect,
    component: () => import("/usr/src/nuxt3-app/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexEAfN1CIUWoMeta?.name ?? "index",
    path: indexEAfN1CIUWoMeta?.path ?? "/",
    meta: indexEAfN1CIUWoMeta || {},
    alias: indexEAfN1CIUWoMeta?.alias || [],
    redirect: indexEAfN1CIUWoMeta?.redirect,
    component: () => import("/usr/src/nuxt3-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginUVQbZYKsyxMeta?.name ?? "login",
    path: loginUVQbZYKsyxMeta?.path ?? "/login",
    meta: loginUVQbZYKsyxMeta || {},
    alias: loginUVQbZYKsyxMeta?.alias || [],
    redirect: loginUVQbZYKsyxMeta?.redirect,
    component: () => import("/usr/src/nuxt3-app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: matchesATFdXCrSX8Meta?.name ?? "matches",
    path: matchesATFdXCrSX8Meta?.path ?? "/matches",
    meta: matchesATFdXCrSX8Meta || {},
    alias: matchesATFdXCrSX8Meta?.alias || [],
    redirect: matchesATFdXCrSX8Meta?.redirect,
    component: () => import("/usr/src/nuxt3-app/pages/matches.vue").then(m => m.default || m)
  },
  {
    name: notesrlkSR9s2HEMeta?.name ?? "notes",
    path: notesrlkSR9s2HEMeta?.path ?? "/notes",
    meta: notesrlkSR9s2HEMeta || {},
    alias: notesrlkSR9s2HEMeta?.alias || [],
    redirect: notesrlkSR9s2HEMeta?.redirect,
    component: () => import("/usr/src/nuxt3-app/pages/notes.vue").then(m => m.default || m)
  },
  {
    name: predictlzdytUN7MxMeta?.name ?? "predict",
    path: predictlzdytUN7MxMeta?.path ?? "/predict",
    meta: predictlzdytUN7MxMeta || {},
    alias: predictlzdytUN7MxMeta?.alias || [],
    redirect: predictlzdytUN7MxMeta?.redirect,
    component: () => import("/usr/src/nuxt3-app/pages/predict.vue").then(m => m.default || m)
  },
  {
    name: scoutFroJopb6YKMeta?.name ?? "scout",
    path: scoutFroJopb6YKMeta?.path ?? "/scout",
    meta: scoutFroJopb6YKMeta || {},
    alias: scoutFroJopb6YKMeta?.alias || [],
    redirect: scoutFroJopb6YKMeta?.redirect,
    component: () => import("/usr/src/nuxt3-app/pages/scout.vue").then(m => m.default || m)
  },
  {
    name: settingsHEhVIocTCuMeta?.name ?? "settings",
    path: settingsHEhVIocTCuMeta?.path ?? "/settings",
    meta: settingsHEhVIocTCuMeta || {},
    alias: settingsHEhVIocTCuMeta?.alias || [],
    redirect: settingsHEhVIocTCuMeta?.redirect,
    component: () => import("/usr/src/nuxt3-app/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: _91id_931583b0flOMMeta?.name ?? "teams-id",
    path: _91id_931583b0flOMMeta?.path ?? "/teams/:id()",
    meta: _91id_931583b0flOMMeta || {},
    alias: _91id_931583b0flOMMeta?.alias || [],
    redirect: _91id_931583b0flOMMeta?.redirect,
    component: () => import("/usr/src/nuxt3-app/pages/teams/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93jF0kJABXlWMeta?.name ?? "teams-attachments-id",
    path: _91id_93jF0kJABXlWMeta?.path ?? "/teams/attachments/:id()",
    meta: _91id_93jF0kJABXlWMeta || {},
    alias: _91id_93jF0kJABXlWMeta?.alias || [],
    redirect: _91id_93jF0kJABXlWMeta?.redirect,
    component: () => import("/usr/src/nuxt3-app/pages/teams/attachments/[id].vue").then(m => m.default || m)
  },
  {
    name: index5xpbIqJnRtMeta?.name ?? "teams-attachments",
    path: index5xpbIqJnRtMeta?.path ?? "/teams/attachments",
    meta: index5xpbIqJnRtMeta || {},
    alias: index5xpbIqJnRtMeta?.alias || [],
    redirect: index5xpbIqJnRtMeta?.redirect,
    component: () => import("/usr/src/nuxt3-app/pages/teams/attachments/index.vue").then(m => m.default || m)
  },
  {
    name: indexiZOyfyVr5MMeta?.name ?? "teams",
    path: indexiZOyfyVr5MMeta?.path ?? "/teams",
    meta: indexiZOyfyVr5MMeta || {},
    alias: indexiZOyfyVr5MMeta?.alias || [],
    redirect: indexiZOyfyVr5MMeta?.redirect,
    component: () => import("/usr/src/nuxt3-app/pages/teams/index.vue").then(m => m.default || m)
  },
  {
    name: usersK9lU4U3bY3Meta?.name ?? "users",
    path: usersK9lU4U3bY3Meta?.path ?? "/users",
    meta: usersK9lU4U3bY3Meta || {},
    alias: usersK9lU4U3bY3Meta?.alias || [],
    redirect: usersK9lU4U3bY3Meta?.redirect,
    component: () => import("/usr/src/nuxt3-app/pages/users.vue").then(m => m.default || m)
  }
]